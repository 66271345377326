import type { Item } from "@laast-io/types";
import { hydrateNumber } from "./common";

export function hydrateItemRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Item['id'] | null | undefined
  }

  return hydrateItem(input)
}

export function hydrateItem(
  input: any
) {
  return {
    ...input as Item,

    // hydrate numbers
    in_stock: hydrateNumber(input.in_stock),
    stock_threshold_optimal: hydrateNumber(input.stock_threshold_optimal),
    stock_threshold_warning: hydrateNumber(input.stock_threshold_warning),
    tax_ratio: hydrateNumber(input.tax_ratio),
    unitary_cost: hydrateNumber(input.unitary_cost),
    unitary_price: hydrateNumber(input.unitary_price),

    /** @deprecated */
    stock_threshold_critical: hydrateNumber(input.stock_threshold_critical),
  }
}
